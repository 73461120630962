import React, { useEffect, useState } from 'react'
import CustomModal from '../../../../../../components/modal/CustomModal'
import Form from '../../../../../../components/form/index.jsx'
import MainTitle from '../../../../../../components/text/drawer/mainTitle'
import SubtitleBlue from '../../../../../../components/text/drawer/subtitleBlue/index.jsx'
import LabelDrawer from '../../../../../../components/text/drawer/label/index.jsx'

import { Select, Checkbox, Switch, Input, notification } from 'antd'

import style from './editWorkOrderAttention.module.css'
import BottomSection from '../../../../../../components/form/bottomSection/index.jsx'
import { myFetch, myFetchGet } from '../../../../../../services/services.js'
import { checkEmptyKeys, emptyInputsCheck, validarCampos } from '../../../../../../helpers/helpers.js'
import styled from 'styled-components'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min.js'

const EditWorkOrderAttention = ({ setViewEditWorkOrder, data }) => {

	const history = useHistory();
	const { TextArea } = Input
	const { id_unico, technician, auxiliaries, status, comments, updated, supervisor } = data

	const [listStatus, setListStatus] = useState([])
	const [listTechnicians, setListTechnicians] = useState([])
	const [listAuxiliaries, setListAuxiliaries] = useState([])

	const [valueTechnicians, setValueTechnicians] = useState(technician.id)
	const [valueAuxiliaries, setValueAuxiliaries] = useState(null)
	const [valueStatus, setValueStatus] = useState(status)

	const [valueSupportSupervisor, setValueSupportSupervisor] = useState(supervisor)
	const [valueComments, setValueComments] = useState(comments)

	const [viewComments, setViewComments] = useState(false)

	const [camposIncompletos, setCamposIncompletos] = useState(null)

	const getTechnicians = async () => {
		try {
			const { data } = await myFetchGet(`api/v1/wo/valid_main_technicians/?id_unico=${id_unico}`)

			const list = data.map(item => ({
				value: item.id,
				label: `${item.name}  ${item.last_name}`
			}))
			setListTechnicians(list)
		} catch (error) { }
	}

	const getAuxiliaries = async () => {
		try {
			const { data } = await myFetchGet(`api/v1/wo/valid_auxiliaries_technicians/?id_unico=${id_unico}`)
			const list = data.map(item => ({
				value: item.id,
				label: `${item.name}  ${item.last_name}`
			}))
			setListAuxiliaries(list)
		} catch (error) { }
	}

	const getStatus = async () => {
		try {
			const { data } = await myFetchGet(`api/v1/wo/list_status/?id_unico=${id_unico}`)

			const list = data.map(item => ({
				value: item.id,
				label: item.name
			}))
			setListStatus(list)
		} catch (error) { }
	}

	const onHandlerTechnicians = (value) => {
		setValueTechnicians(value)
	}

	const onHandlerSupportSupervisor = (e) => {
		setValueSupportSupervisor(e.target.checked)
	}

	const onHandlerAuxiliaries = (value) => {
		setValueAuxiliaries(value)
	}

	const onHandlerSwitch = (e) => {
		setViewComments(e)
	}

	const onHandlerComments = (e) => {
		setValueComments(e.target.value)
	}

	const onHandlerStatus = (value) => {
		setValueStatus(value)
	}

	const onUpdate = async () => {
		const body = {
			technician: valueTechnicians,
			auxiliaries: valueAuxiliaries,
			status: valueStatus,
			supervisor: valueSupportSupervisor,
		}

		if (viewComments) {
			body["comments"] = valueComments
		}

		const clavesVacias = checkEmptyKeys(body)
		setCamposIncompletos(clavesVacias)

		if (!validarCampos(body)) {
			notification.warning({
				style: { fontWeight: 'bold' },
				message: "Por favor, completa todos los campos",
				placement: 'bottomLeft',
				duration: 3,
			})
		}

		try {
			await myFetch(`api/v1/wo/${id_unico}/`, body, 'PATCH')
			notification.success({
				style: { fontWeight: 'bold' },
				message: "Orden de trabajo actualizada",
				placement: 'bottomLeft',
				duration: 1.5,
				onClose: () => history.push("/listWorkOrders")
			})
		} catch (error) { }
	}

	useEffect(() => {
		getTechnicians()
		getAuxiliaries()
		getStatus()
	}, [])

	useEffect(() => {
		const data = auxiliaries.map(item => item.id)
		setValueAuxiliaries(data)
	}, [])

	useEffect(() => {
		if (valueComments === null) {
			setViewComments(false)
		} else {
			setViewComments(true)
		}
	}, [])

	useEffect(() => {
		if (viewComments === false) {
			setValueComments(null)
		} else {
			setValueComments(comments)
		}
	}, [viewComments])



	return (
		<CustomModal onClick={() => setViewEditWorkOrder(false)}>
			<Form>
				<MainTitle value='Atendimiento orden de trabajo' onClick={() => setViewEditWorkOrder(false)} />

				<SubtitleBlue value='Información general' />

				<div className={style.flex_column}>
					<LabelDrawer value='Técnico principal' />
					<Select
						style={{ flex: 1 }}
						options={listTechnicians}
						value={valueTechnicians}
						onChange={onHandlerTechnicians}
					/>
				</div>

				<Checkbox
					className={style.Checkbox}
					checked={valueSupportSupervisor}
					onChange={onHandlerSupportSupervisor}
				>
					Amerita apoyo del supervisor
				</Checkbox>

				<div className={style.flex_column}>
					<LabelDrawer value='Auxiliares' />
					<ConatinerSelect>
						<Select
							mode='multiple'
							style={{ flex: 1, width: '100%' }}
							options={listAuxiliaries}
							value={valueAuxiliaries}
							onChange={onHandlerAuxiliaries}
							status={emptyInputsCheck(camposIncompletos, 'auxiliaries') ? 'error' : ''}
						/>
					</ConatinerSelect>
				</div>

				<span className={style.container_switch}>
					<p className={`${style.textfont12} ${style.bold}`}>¿Desea agregar comentario adicional?</p>
					<span className={style.subcontaine_switch}>
						<p className={style.textfont12}>No</p>
						<Switch onChange={onHandlerSwitch} checked={viewComments} />
						<p className={style.textfont12}>Si</p>
					</span>
				</span>

				{viewComments && (
					<TextArea
						className={`${style.text_area} ${style.textfont12}`}
						value={valueComments}
						onChange={onHandlerComments}
						status={emptyInputsCheck(camposIncompletos, 'comments') ? 'error' : ''}
					/>
				)}


				<div className={style.flex_column}>
					<SubtitleBlue value='Información de atendimiento' />
					<p className={`${style.textfont12} ${style.color868D96}`}>Última actualización realizada el día {updated}</p>
				</div>

				<div className={style.flex_column}>
					<p className={style.textfont12}>Status</p>
					<Select
						options={listStatus}
						value={valueStatus}
						onChange={onHandlerStatus}
					/>
				</div>

				<BottomSection onCancel={() => setViewEditWorkOrder(false)} onAcept={onUpdate} />

			</Form>
		</CustomModal>
	)
}

export default EditWorkOrderAttention

const ConatinerSelect = styled.div`
.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
	width: 100%;
	height: 40px;
	padding: 0 11px;
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector {
	position: relative;
	background-color: #ffffff;
	border: 1px solid #d9d9d9;
	transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
	min-height: 40px;
	cursor: pointer;
}

.ant-select-selector {
	width: 100%;
	height: auto !important;
}

.ant-select-multiple .ant-select-selection-overflow {
	display: flex;
	flex-wrap: wrap;
	max-width: 100%;
}

.ant-select-multiple .ant-select-selection-item {
	display: flex;
	align-items: center;
	padding-bottom: 5px;
}
`